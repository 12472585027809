import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Notification from "./notification";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        margin: '0.75rem',
        maxWidth: '420px'
    }
}));

const ToastNotification = ({notification, showToast, handleClose}) => {

    const classes = useStyles();

    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setData(notification);
    }, [notification]);

    useEffect(() => {
        setOpen(showToast);
    }, [showToast]);

    return (
        <Snackbar
            autoHideDuration={10000}
            anchorOrigin={{ vertical:'top', horizontal:'right' }}
            open={open}
            onClose={handleClose}
            className={classes.root}
        >
            <Notification notificationData={data}></Notification>
        </Snackbar>
    );
}

export default ToastNotification;