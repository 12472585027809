import React, {useEffect, useState} from 'react';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import ProfileCard from '../user/profile-card';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    headerRoot: {
        backgroundColor: theme.palette.primary.main,
        zIndex: 1,
        color: theme.palette.background.default,
        paddingTop: '0.5em',
        flexGrow: 1,
    },
    profileArea: {
        width: '100%',
        float: 'right',
        textAlign: 'right',
        '& > *': {
            marginRight: '0.5em'
        },
    },
    profileAreaMd: {
        width: '100%',
        float: 'right',
        textAlign: 'right',
        '& > *': {
            marginRight: '0.5em',
        },
    },
    fabButtons: {
        background: theme.palette.primary.main,
        color: theme.palette.background.default
    }
}));

const KonnectHeader = ({ name, picture, startTour}) => {

    const classes = useStyles();
    let n = name.substring(0, name.indexOf(' '));
    /*if(n.length > 10) {
        n = n.substring(0, 9);
    }*/
    const [anchorEl, setAnchorEl] = useState(null);
    //const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [notificationEnabled, setNotificationEnabled] = useState(true);

    const showProfile = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setNotificationEnabled(Notification.permission === "granted");
    }, []);

    /*const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsDrawerOpen(open);
    };*/


    const open = Boolean(anchorEl);

    return (
        <div id="konnectHeader" name="konnectHeader" className={classes.headerRoot}>
            <Hidden smDown>
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <img id="konnectLogo" src={"/images/logo2x.png"} alt="Logo" />
                    </Grid>
                    <Grid item xs={2}>
                        {/* {!notificationEnabled && (
                            <Alert severity="warning">
                                <Typography variant="body1" >
                                    Please enable notifications
                                </Typography>
                            </Alert>
                        )} */}
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={3} >
                        <div className={classes.profileArea}>
                            <Typography variant="overline" style={{overflow: 'wrap'}}>
                                Welcome, {n}
                            </Typography>
                            <Fab id="profileCard" onClick={showProfile} className={classes.fabButtons}>
                                <Avatar alt={name} src={picture} >
                                </Avatar>
                            </Fab>
                            {/* <Fab className={classes.fabButtons} onClick={() => startTour()}>
                                <InfoIcon />
                            </Fab> */}
                            {/*<Hidden mdDown>
                                <Fab onClick={onChangeLayoutClick} className={classes.fabButtons}>
                                    <ViewCompactIcon></ViewCompactIcon>
                                </Fab>
                            </Hidden>*/}
                        </div>

                    </Grid>
                </Grid>
            </Hidden>

            <Popover
                id={"ProfilePopover"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <ProfileCard name={name} picture={picture}></ProfileCard>
            </Popover>

        </div>
    );
}

export default KonnectHeader;

