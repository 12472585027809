import { Auth } from "aws-amplify";
import { removeItem, setItem } from "./local-storage";
import konnectConfig from "./api/amplify-config";

import Amplify from "aws-amplify";

const federatedConfig = {
  provider: "google",
};

Amplify.configure(konnectConfig);

export const currentAuthenticatedUser = async () => {
  try {
    const loggedInUser = await Auth.currentAuthenticatedUser();
    return formUserObject(loggedInUser);
  } catch (e) {
    //console.log("Error on get current user (currentAuthenticatedUser) ", e);
    return undefined;
  }
};

export const checkLogin = async (redirectProps) => {
  window.LOG_LEVEL = "DEBUG";
  try {
    const loggedInUser = await Auth.currentAuthenticatedUser();
    return formUserObject(loggedInUser);
  } catch (e) {
    //console.log("Error on get current user (checkLogin)", e);
  }

  if (redirectProps && redirectProps !== null) {
    setItem("redirectProps", redirectProps);
  }

  try {
    await Auth.federatedSignIn(federatedConfig);
  } catch (e) {
    //console.log("Error logging in (checkLogin)", e);
  }
};

const formUserObject = (authResp) => {
  const { payload } = authResp.signInUserSession.idToken;
  let user = {
    id: payload.identities[0].userId,
    email: payload.email,
    name: `${payload.given_name} ${payload.family_name}`,
    roles: payload["cognito:groups"],
    picture: payload.picture,
  };
  return user;
};

export const logout = () => {
  try {
    removeItem("redirectProps");
    Auth.signOut();
  } catch (e) {
    //console.log("Error signout", e);
  }
};

export const getToken = async () => {
  let sessionToken = await Auth.currentSession();
  return sessionToken.idToken.jwtToken;

  /*let token = null;
    try {
        const federatedInfo = await Cache.getItem('federatedInfo');
        //console.log('federatedInfo', federatedInfo);
        token = federatedInfo.token;
    } catch(e) {
        //console.log(e);
    }
    return token;*/
};


