import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import Notification from "./notification";

const MS_IN_DAY = 86400000;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "100%",
    minHeight: "12rem",
    maxHeight: "14rem",
    backgroundColor: theme.palette.background.default,
  },
  rootVertical: {
    overflow: "auto",
    width: "100%",
    height: "90vh",
    backgroundColor: theme.palette.background.default,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.default,
  },
  title: {},
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

const CardsContainer = (props) => {
  const [datacallByCategory, setDatacallByCategory] = useState({});
  const [notificationsByCategory, setNotificationsByCategory] = useState({});
  //const [dataCallList, setDataCallList] = useState({})
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const notificationJson = props.notifications;
  const datacallJson = props.datacallList;

  useEffect(() => {
    const notificationsWithDate = [];
    notificationJson
      .sort(function (a, b) {
        return (
          new Date(a.datacallNotification.dueDate) -
          new Date(b.datacallNotification.dueDate)
        );
      })
      .reverse();
    notificationJson &&
      notificationJson.map((n) => {
        const categorization = addDateCategory(n);
        notificationsWithDate.push({ ...n, categorization });
      });
    const notificationsByCategory = _.groupBy(
      notificationsWithDate,
      "categorization"
    );

    setNotificationsByCategory(notificationsByCategory);
  }, [props.notifications]);

  useEffect(() => {
    //console.log("in useffect");
    const datacallWithDate = [];
    datacallJson.sort(function (a, b) {
      return (
        new Date(a.datacallNotification.dueDate) -
        new Date(b.datacallNotification.dueDate)
      );
    });

    datacallJson &&
      datacallJson.map((n) => {
        if (n.status != "RECEIVED") {
          const datacallCategorization = addDueDateCategory(n);
          datacallWithDate.push({ ...n, datacallCategorization });
        }
      });
    const datacallByCategory = _.groupBy(
      datacallWithDate,
      "datacallCategorization"
    );

    setDatacallByCategory(datacallByCategory);
    //  setDataCallList(props.datacallList)
    setLoading(false);
  }, [props.datacallList]);

  const addDateCategory = (notification) => {
    let d = new Date(notification.createdAt);
    d.setHours(0, 0, 0, 0);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let cat = "";

    let dayDiff = Math.round((today - d) / MS_IN_DAY);
    if (dayDiff <= 1) {
      cat = "Today";
    } else if (dayDiff <= 2) {
      cat = "Yesterday";
    } else {
      let weekDiff = Math.floor(dayDiff / 7);
      if (weekDiff === 0) {
        cat = "Earlier this week";
      } else if (weekDiff === 1) {
        cat = `Last week`;
      } else {
        cat = `${weekDiff} weeks back`;
      }
    }

    return cat;
  };

  const addDueDateCategory = (notification) => {
    let d = new Date(notification.datacallNotification.dueDate);

    d.setHours(0, 0, 0, 0);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let cat = "";

    let dayDiff = Math.round((today - d) / MS_IN_DAY);
    if (dayDiff > 0) {
      if (dayDiff <= 1) {
        cat = "Today";
      } else if (dayDiff <= 2) {
        cat = "Yesterday";
      } else {
        let weekDiff = Math.floor(dayDiff / 7);
        if (weekDiff === 0) {
          cat = "Earlier this week";
        } else if (weekDiff === 1) {
          cat = `Last week`;
        } else {
          cat = `${weekDiff} weeks back`;
        }
      }
    } else {
      let weekDiff = Math.floor(dayDiff / 7) * -1;
      if (weekDiff === 0) {
        cat = "Due this week";
      } else if (weekDiff === 1) {
        cat = `Due next week`;
      } else {
        cat = `In ${weekDiff} weeks`;
      }
    }

    return cat;
  };

  return (
    <Fragment>
      <Paper id="cardsContainer" className={classes.rootVertical} elevation={0}>
        {loading && (
          <React.Fragment>
            <Skeleton
              animation="wave"
              height={10}
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={10} width="80%" />
          </React.Fragment>
        )}
        {!loading &&
          Object.keys(datacallByCategory).map((c, i) => (
            <List
              key={"notifications" + c}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  id="nested-list-subheader"
                  style={{ textAlign: "left" }}
                >
                  <Typography
                    variant={"subtitle2"}
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {c}
                  </Typography>
                </ListSubheader>
              }
            >
              {datacallByCategory[c].map((n, j) => (
                <ListItem key={"notifications" + c + j}>
                  <Notification
                    key={"notification" + n.title + j}
                    notificationDatacallJson={n}
                    cardIndex={j}
                    onDatacallClick={props.onDatacallClick}
                  ></Notification>
                </ListItem>
              ))}
            </List>
          ))}
        {!loading &&
          Object.keys(notificationsByCategory).map((c, i) => (
            <List
              key={"notifications" + c}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  id="nested-list-subheader"
                  style={{ textAlign: "left" }}
                >
                  <Typography
                    variant={"subtitle2"}
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {c}
                  </Typography>
                </ListSubheader>
              }
            >
              {notificationsByCategory[c].map((n, j) => (
                <ListItem key={"notifications" + c + j}>
                  <Notification
                    key={"notification" + n.title + j}
                    notificationDatacallJson={n}
                    cardIndex={j}
                  ></Notification>
                </ListItem>
              ))}
            </List>
          ))}
      </Paper>
    </Fragment>
  );
};

export default CardsContainer;
