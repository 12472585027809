import React, { Component, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import KonnectHeader from "../components/layout/konnect-header";
import Button from "@material-ui/core/Button";
import ToastNotification from "../components/notifications/toast-notification";

import { currentAuthenticatedUser } from "../services/auth-services";
import {
  getCurrentUser,
  getDatacallByContract,
  getPartnerDatacalls,
  updatePartnerDatacall,
} from "../services/api/client";

class DatacallConfirmPage extends Component {
  constructor(props) {
    super(props);
    const url = props.match.url;

    this.state = {
      // currentUser: {name: 'Shan Palanivelu', picture: undefined},
      allNotifications: [
        {
          __typename: "Notification",
          actionLink:
            "https://www.appsheet.com/start/7ea1f633-1162-4407-b1ad-fa8f90e5e670#appName=Dojos-1396489&page=dashboard&table=Training&view=Training%20User%20View&row=3fd2e20c",
          actionText: "View Details",
          actionType: "EXTERNAL_LINK",
          approvalStatus: "APPROVED",
          "approvalStatus#createdAt": "APPROVED#2020-08-04T00:00Z",
          createdAt: "2020-08-04T00:00Z",
          createdBy: "surya.polina@karsun-llc.com",
          id: "3fd2e20c",
          notificationDesc:
            "Please respond to the data call for Task Order FLEET under test201.",
          notificationTitle: "Action Required: Data call",
          priority: "IMMEDIATE",
          ref: "Dojo",
          requiresApproval: "NO",
          status: "NOTIFIED",
          topicsCsv: "#Karsun,#Comet,#Fleet",
          updatedAt: "2020-08-05T02:17:42.961Z",
        },
        // {
        //   __typename: "Notification",
        //   actionLink:
        //     "https://www.appsheet.com/start/7ea1f633-1162-4407-b1ad-fa8f90e5e670#appName=Dojos-1396489&page=dashboard&table=Training&view=Training%20User%20View&row=3fd2e20c",
        //   actionText: "View Details",
        //   actionType: "EXTERNAL_LINK",
        //   approvalStatus: "APPROVED",
        //   "approvalStatus#createdAt": "APPROVED#2020-08-04T00:00Z",
        //   createdAt: "2020-08-04T00:00Z",
        //   createdBy: "shanmuga.palanivelu@karsun-llc.com",
        //   id: "3fd2e20c",
        //   notificationDesc:
        //     "Please respond to the data call for Task Order FLEET under COMET.",
        //   notificationTitle: "Action Required: Data call",
        //   priority: "IMMEDIATE",
        //   ref: "Dojo",
        //   requiresApproval: "NO",
        //   status: "NOTIFIED",
        //   topicsCsv: "#Karsun,#Comet,#Fleet",
        //   updatedAt: "2020-08-05T02:17:42.961Z",
        // },
      ],
      currentUser: undefined,
      partnerDatacallNotificationId: url.substring(
        url.lastIndexOf("/") + 1,
        url.length
      ),
      partnerdatacallList: {},
      partnerInfo: {},
      loading: true,
      datacallValidity: false,
      showAcknowledgment: false,
      datacallAcknowledged: false,
      fileURL: "",
    };
    //console.log(this.state.partnerDatacallNotificationId);
  }

  async componentDidMount() {
    /* const currentUser = await currentAuthenticatedUser();
        //console.log(currentUser)
        this.setState({currentUser: currentUser});
        const userRoles = currentUser.roles;*/

    const currentUser = await currentAuthenticatedUser();
    this.setState({ currentUser: currentUser });

    //getCurrentUser has to be replaced with authenticated user
    const userVal = await getCurrentUser(currentUser.email);
    this.setState({ partnerInfo: userVal.data.getPKUser.partner });
    const partnerdatacallList = await this.listPartnerDatacallsForUser(
      userVal.data.getPKUser.userGroups.items
    );
    this.setState({ partnerdatacallList: partnerdatacallList });
    this.checkPartnerDatacall(this.state.partnerDatacallNotificationId);
    if (this.state.datacallValidity) {
      const result = await updatePartnerDatacall(
        this.state.partnerDatacallNotificationId,
        "ACKNOWLEDGED"
      );

      this.setState({
        showAcknowledgment: true,
        fileURL: result.data.updatePKDatacallNotificationPartner.copyFileURL
      });
    }
  }

  checkPartnerDatacall = async (partnerDatacallNotificationId) => {
    let datacallValidity = false,
      datacallAcknowledged = false;
    this.state.partnerdatacallList.map((datacall) => {
      if (
        partnerDatacallNotificationId === datacall.id &&
        datacall.status === "SENT"
      ) {
        datacallValidity = true;
      }
      if (
        partnerDatacallNotificationId === datacall.id &&
        datacall.status === "ACKNOWLEDGED"
      ) {
        datacallAcknowledged = true;
      }
    });
    this.setState({
      datacallValidity: datacallValidity,
      datacallAcknowledged: datacallAcknowledged,
      loading: false
    });
  };

  listPartnerDatacallsForUser = async () => {
    const partnerDatacallItems = await getPartnerDatacalls(
      this.state.partnerInfo.id
    );

    return partnerDatacallItems.data.partnerdatacallByPartner.items;
  };

  render() {
    if (!this.state.currentUser || this.state.currentUser === null) {
      return null;
    }

    return (
      <Fragment>
        {!this.state.loading && (
          <Grid container spacing={1}>
            <Grid item md={12}>
              <KonnectHeader
                name={this.state.currentUser.name}
                picture={this.state.currentUser.picture}
              ></KonnectHeader>
            </Grid>

            {/* <Grid item xl={3} lg={3} md={12}></Grid> */}
            <Grid item xl={12} lg={12} md={12}>
              <Box style={{ width: "100%", marginBottom: "1rem" }}></Box>

              <Box
                style={{
                  width: "100%",
                  height: "60vh",
                  border: "2px",
                  borderColor: "black",
                  marginTop: "1rem",
                }}
              >
                {!this.state.datacallValidity &&
                  !this.state.datacallAcknowledged && [
                    <h4>
                      Something went wrong. Please contact the Karsun team
                    </h4>,
                  ]}
                {this.state.datacallAcknowledged && [
                  <h4>The datacall has already been ACKNOWLEDGED</h4>,
                ]}
                {this.state.showAcknowledgment && [
                  <h4>The datacall has been ACKNOWLEDGED</h4>,

                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      window.open(this.state.fileURL);
                    }}
                  >
                    OPEN FILE
                  </Button>,
                  <br />,
                  <br />,
                ]}

                <Link to="/home">Home</Link>
              </Box>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

export default withRouter(DatacallConfirmPage);
