import React, { useEffect, useState } from "react";
import {
  checkLogin,
  currentAuthenticatedUser,
} from "../services/auth-services";
import { useLocation, useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setItem, getItem } from "../services/local-storage";

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
  },
  media: {
    height: 140,
    width: 200,
  },
});

const AuthPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [error, setError] = useState(false);
  const fromDatacallPage = location.pathname.indexOf("datacall") > 0;

  useEffect(() => {
    const enforceLogin = async () => {
      //console.log(location);
      if (location.search.indexOf("code") >= 0) {
        let checkCounter = 0;
        const authChecker = setInterval(async () => {
          checkCounter++;
          const user = await currentAuthenticatedUser();
          if (user) {
            clearInterval(authChecker);
            const pathname =
              getItem("pathname") === null
                ? ""
                : getItem("pathname").replaceAll('"', "").substring(1);
            //console.log(pathname.indexOf("datacall/"));
            //console.log(pathname);
            if (pathname.indexOf("datacall/") >= 0) {
              history.replace(pathname);
              setItem("pathname", "");
            } else {
              history.replace("/home");
              setItem("pathname", "");
            }
          } else {
            if (checkCounter > 4) {
              clearInterval(authChecker);
              setError(true);
            }
          }
        }, 1000);
      } else {
        if (location.search.indexOf("redirect") >= 0) {
          setItem("redirect", location.search);
        }
        const user = await checkLogin();
        if (user) {
          const pathname =
            getItem("pathname") === null
              ? ""
              : getItem("pathname").replaceAll('"', "").substring(1);
          //console.log(pathname.indexOf("datacall/"));
          //console.log(pathname);
          if (pathname.indexOf("datacall/") >= 0) {
            history.replace(pathname);
            setItem("pathname", "");
          } else {
            history.replace("/home");
            setItem("pathname", "");
          }
        } else {
        }
      }
    };

    enforceLogin();
  });

  return (
    <Container>
      <Card className={classes.root}>
        <CardContent>
          {error ? (
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image="/images/logo.png"
                  title="Login to Konnect"
                />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Log in failed
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  There was a problem authenticating. Usually this is resolved
                  on retry. You could retry by clicking the button below. If the
                  problem persists, please contact Konnect administrators.
                </Typography>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    href="/logout"
                    target="_blank"
                  >
                    Try again
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          ) : (
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image="/images/logo2x.png"
                  title="Login to Konnect"
                />
              </CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Konnect(ing..)
                </Typography>
                <CircularProgress />
              </CardContent>
            </Card>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default AuthPage;
