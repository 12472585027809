export const setItem = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

export const loadState = () => {
    let state = undefined;
    try {
        const serializedState = localStorage.getItem('state');
        if(serializedState !== null) {
            state = JSON.parse(serializedState);
        }
    } catch(e) {
    }
    return state;
};

export const saveState = state => {
  try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
  }  catch(e) {}
};

export const addItemToKey = (key, value) => {
    const data = getObject(key);
    if(data) {
        data.push(value);
        window.localStorage.setItem(key, JSON.stringify(data));
    } else {
        const items = [];
        items.push(value);
        window.localStorage.setItem(key, JSON.stringify(items));
    }
};

export const addItemIfNotExist = (storageKey, identifierKey, values) => {
    let value=values.location
    const data = getObject(storageKey);
    if(data) {
        const items = data.filter(d => {
            return d[identifierKey] === value[identifierKey];
        });
        if(items || items.length >0) {
            data.push(value);
            window.localStorage.setItem(storageKey, JSON.stringify(data));
        }
    } else {
        const items = [];
        items.push(value);
        window.localStorage.setItem(storageKey, JSON.stringify(items));
    }
};

export const getItem = (key) => {
    return window.localStorage.getItem(key);
};

export const getObject = (key) => {
    try {
        const item = getItem(key);
        if (item !== undefined && item !== null && item !== 'undefined' && item !== 'null') {
            return JSON.parse(item);
        }
    } catch (e) {

    }
    return undefined;
};

export const removeItem = (key) => {
    window.localStorage.removeItem(key);
};
