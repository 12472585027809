/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPkContract = /* GraphQL */ `
  mutation CreatePkContract(
    $input: CreatePKContractInput!
    $condition: ModelPKContractConditionInput
  ) {
    createPKContract(input: $input, condition: $condition) {
      id
      name
      agency
      status
      contractNumber
      description
      popStartDate
      popEndDate
      driveId
      publicFolderId
      createdAt
      updatedAt
    }
  }
`;
export const updatePkContract = /* GraphQL */ `
  mutation UpdatePkContract(
    $input: UpdatePKContractInput!
    $condition: ModelPKContractConditionInput
  ) {
    updatePKContract(input: $input, condition: $condition) {
      id
      name
      agency
      status
      contractNumber
      description
      popStartDate
      popEndDate
      driveId
      publicFolderId
      createdAt
      updatedAt
    }
  }
`;
export const deletePkContract = /* GraphQL */ `
  mutation DeletePkContract(
    $input: DeletePKContractInput!
    $condition: ModelPKContractConditionInput
  ) {
    deletePKContract(input: $input, condition: $condition) {
      id
      name
      agency
      status
      contractNumber
      description
      popStartDate
      popEndDate
      driveId
      publicFolderId
      createdAt
      updatedAt
    }
  }
`;
export const createPkTaskOrder = /* GraphQL */ `
  mutation CreatePkTaskOrder(
    $input: CreatePKTaskOrderInput!
    $condition: ModelPKTaskOrderConditionInput
  ) {
    createPKTaskOrder(input: $input, condition: $condition) {
      id
      name
      description
      contractId
      status
      taskOrderNumber
      folderId
      popStartDate
      popEndDate
      rfpReleaseDate
      proposalDueDate
      qandaDate
      publicFolderId
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePkTaskOrder = /* GraphQL */ `
  mutation UpdatePkTaskOrder(
    $input: UpdatePKTaskOrderInput!
    $condition: ModelPKTaskOrderConditionInput
  ) {
    updatePKTaskOrder(input: $input, condition: $condition) {
      id
      name
      description
      contractId
      status
      taskOrderNumber
      folderId
      popStartDate
      popEndDate
      rfpReleaseDate
      proposalDueDate
      qandaDate
      publicFolderId
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePkTaskOrder = /* GraphQL */ `
  mutation DeletePkTaskOrder(
    $input: DeletePKTaskOrderInput!
    $condition: ModelPKTaskOrderConditionInput
  ) {
    deletePKTaskOrder(input: $input, condition: $condition) {
      id
      name
      description
      contractId
      status
      taskOrderNumber
      folderId
      popStartDate
      popEndDate
      rfpReleaseDate
      proposalDueDate
      qandaDate
      publicFolderId
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPkPartner = /* GraphQL */ `
  mutation CreatePkPartner(
    $input: CreatePKPartnerInput!
    $condition: ModelPKPartnerConditionInput
  ) {
    createPKPartner(input: $input, condition: $condition) {
      id
      name
      description
      status
      address
      primaryContact
      createdAt
      updatedAt
    }
  }
`;
export const updatePkPartner = /* GraphQL */ `
  mutation UpdatePkPartner(
    $input: UpdatePKPartnerInput!
    $condition: ModelPKPartnerConditionInput
  ) {
    updatePKPartner(input: $input, condition: $condition) {
      id
      name
      description
      status
      address
      primaryContact
      createdAt
      updatedAt
    }
  }
`;
export const deletePkPartner = /* GraphQL */ `
  mutation DeletePkPartner(
    $input: DeletePKPartnerInput!
    $condition: ModelPKPartnerConditionInput
  ) {
    deletePKPartner(input: $input, condition: $condition) {
      id
      name
      description
      status
      address
      primaryContact
      createdAt
      updatedAt
    }
  }
`;
export const createPkContractUserGroup = /* GraphQL */ `
  mutation CreatePkContractUserGroup(
    $input: CreatePKContractUserGroupInput!
    $condition: ModelPKContractUserGroupConditionInput
  ) {
    createPKContractUserGroup(input: $input, condition: $condition) {
      id
      contractId
      partnerId
      folderId
      taskOrderDrives {
        name
        folderId
      }
      email
      users
      status
      name
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      userGroupMapping {
        items {
          id
          contractUserGroupId
          userId
          status
          createdAt
          updatedAt
          contractUserGroup {
            id
            contractId
            partnerId
            folderId
            taskOrderDrives {
              name
              folderId
            }
            email
            users
            status
            name
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroupMapping {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          user {
            id
            name
            email
            secondaryEmail
            status
            deviceTokens
            partnerKonnectRoles
            phoneNumber
            createdAt
            updatedAt
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroups {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updatePkContractUserGroup = /* GraphQL */ `
  mutation UpdatePkContractUserGroup(
    $input: UpdatePKContractUserGroupInput!
    $condition: ModelPKContractUserGroupConditionInput
  ) {
    updatePKContractUserGroup(input: $input, condition: $condition) {
      id
      contractId
      partnerId
      folderId
      taskOrderDrives {
        name
        folderId
      }
      email
      users
      status
      name
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      userGroupMapping {
        items {
          id
          contractUserGroupId
          userId
          status
          createdAt
          updatedAt
          contractUserGroup {
            id
            contractId
            partnerId
            folderId
            taskOrderDrives {
              name
              folderId
            }
            email
            users
            status
            name
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroupMapping {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          user {
            id
            name
            email
            secondaryEmail
            status
            deviceTokens
            partnerKonnectRoles
            phoneNumber
            createdAt
            updatedAt
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroups {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deletePkContractUserGroup = /* GraphQL */ `
  mutation DeletePkContractUserGroup(
    $input: DeletePKContractUserGroupInput!
    $condition: ModelPKContractUserGroupConditionInput
  ) {
    deletePKContractUserGroup(input: $input, condition: $condition) {
      id
      contractId
      partnerId
      folderId
      taskOrderDrives {
        name
        folderId
      }
      email
      users
      status
      name
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      userGroupMapping {
        items {
          id
          contractUserGroupId
          userId
          status
          createdAt
          updatedAt
          contractUserGroup {
            id
            contractId
            partnerId
            folderId
            taskOrderDrives {
              name
              folderId
            }
            email
            users
            status
            name
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroupMapping {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          user {
            id
            name
            email
            secondaryEmail
            status
            deviceTokens
            partnerKonnectRoles
            phoneNumber
            createdAt
            updatedAt
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroups {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createPkUserGroupMapping = /* GraphQL */ `
  mutation CreatePkUserGroupMapping(
    $input: CreatePKUserGroupMappingInput!
    $condition: ModelPKUserGroupMappingConditionInput
  ) {
    createPKUserGroupMapping(input: $input, condition: $condition) {
      id
      contractUserGroupId
      userId
      status
      createdAt
      updatedAt
      contractUserGroup {
        id
        contractId
        partnerId
        folderId
        taskOrderDrives {
          name
          folderId
        }
        email
        users
        status
        name
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroupMapping {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      user {
        id
        name
        email
        secondaryEmail
        status
        deviceTokens
        partnerKonnectRoles
        phoneNumber
        createdAt
        updatedAt
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroups {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updatePkUserGroupMapping = /* GraphQL */ `
  mutation UpdatePkUserGroupMapping(
    $input: UpdatePKUserGroupMappingInput!
    $condition: ModelPKUserGroupMappingConditionInput
  ) {
    updatePKUserGroupMapping(input: $input, condition: $condition) {
      id
      contractUserGroupId
      userId
      status
      createdAt
      updatedAt
      contractUserGroup {
        id
        contractId
        partnerId
        folderId
        taskOrderDrives {
          name
          folderId
        }
        email
        users
        status
        name
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroupMapping {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      user {
        id
        name
        email
        secondaryEmail
        status
        deviceTokens
        partnerKonnectRoles
        phoneNumber
        createdAt
        updatedAt
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroups {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deletePkUserGroupMapping = /* GraphQL */ `
  mutation DeletePkUserGroupMapping(
    $input: DeletePKUserGroupMappingInput!
    $condition: ModelPKUserGroupMappingConditionInput
  ) {
    deletePKUserGroupMapping(input: $input, condition: $condition) {
      id
      contractUserGroupId
      userId
      status
      createdAt
      updatedAt
      contractUserGroup {
        id
        contractId
        partnerId
        folderId
        taskOrderDrives {
          name
          folderId
        }
        email
        users
        status
        name
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroupMapping {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      user {
        id
        name
        email
        secondaryEmail
        status
        deviceTokens
        partnerKonnectRoles
        phoneNumber
        createdAt
        updatedAt
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroups {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createPkUser = /* GraphQL */ `
  mutation CreatePkUser(
    $input: CreatePKUserInput!
    $condition: ModelPKUserConditionInput
  ) {
    createPKUser(input: $input, condition: $condition) {
      id
      name
      email
      secondaryEmail
      status
      deviceTokens
      partnerKonnectRoles
      phoneNumber
      createdAt
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      userGroups {
        items {
          id
          contractUserGroupId
          userId
          status
          createdAt
          updatedAt
          contractUserGroup {
            id
            contractId
            partnerId
            folderId
            taskOrderDrives {
              name
              folderId
            }
            email
            users
            status
            name
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroupMapping {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          user {
            id
            name
            email
            secondaryEmail
            status
            deviceTokens
            partnerKonnectRoles
            phoneNumber
            createdAt
            updatedAt
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroups {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updatePkUser = /* GraphQL */ `
  mutation UpdatePkUser(
    $input: UpdatePKUserInput!
    $condition: ModelPKUserConditionInput
  ) {
    updatePKUser(input: $input, condition: $condition) {
      id
      name
      email
      secondaryEmail
      status
      deviceTokens
      partnerKonnectRoles
      phoneNumber
      createdAt
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      userGroups {
        items {
          id
          contractUserGroupId
          userId
          status
          createdAt
          updatedAt
          contractUserGroup {
            id
            contractId
            partnerId
            folderId
            taskOrderDrives {
              name
              folderId
            }
            email
            users
            status
            name
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroupMapping {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          user {
            id
            name
            email
            secondaryEmail
            status
            deviceTokens
            partnerKonnectRoles
            phoneNumber
            createdAt
            updatedAt
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroups {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deletePkUser = /* GraphQL */ `
  mutation DeletePkUser(
    $input: DeletePKUserInput!
    $condition: ModelPKUserConditionInput
  ) {
    deletePKUser(input: $input, condition: $condition) {
      id
      name
      email
      secondaryEmail
      status
      deviceTokens
      partnerKonnectRoles
      phoneNumber
      createdAt
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      userGroups {
        items {
          id
          contractUserGroupId
          userId
          status
          createdAt
          updatedAt
          contractUserGroup {
            id
            contractId
            partnerId
            folderId
            taskOrderDrives {
              name
              folderId
            }
            email
            users
            status
            name
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroupMapping {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          user {
            id
            name
            email
            secondaryEmail
            status
            deviceTokens
            partnerKonnectRoles
            phoneNumber
            createdAt
            updatedAt
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroups {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createPkNotification = /* GraphQL */ `
  mutation CreatePkNotification(
    $input: CreatePKNotificationInput!
    $condition: ModelPKNotificationConditionInput
  ) {
    createPKNotification(input: $input, condition: $condition) {
      id
      title
      description
      link
      contractId
      partners
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      taskOrder {
        id
        name
        description
        contractId
        status
        taskOrderNumber
        folderId
        popStartDate
        popEndDate
        rfpReleaseDate
        proposalDueDate
        qandaDate
        publicFolderId
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePkNotification = /* GraphQL */ `
  mutation UpdatePkNotification(
    $input: UpdatePKNotificationInput!
    $condition: ModelPKNotificationConditionInput
  ) {
    updatePKNotification(input: $input, condition: $condition) {
      id
      title
      description
      link
      contractId
      partners
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      taskOrder {
        id
        name
        description
        contractId
        status
        taskOrderNumber
        folderId
        popStartDate
        popEndDate
        rfpReleaseDate
        proposalDueDate
        qandaDate
        publicFolderId
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePkNotification = /* GraphQL */ `
  mutation DeletePkNotification(
    $input: DeletePKNotificationInput!
    $condition: ModelPKNotificationConditionInput
  ) {
    deletePKNotification(input: $input, condition: $condition) {
      id
      title
      description
      link
      contractId
      partners
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      taskOrder {
        id
        name
        description
        contractId
        status
        taskOrderNumber
        folderId
        popStartDate
        popEndDate
        rfpReleaseDate
        proposalDueDate
        qandaDate
        publicFolderId
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPkPartnerNotification = /* GraphQL */ `
  mutation CreatePkPartnerNotification(
    $input: CreatePKPartnerNotificationInput!
    $condition: ModelPKPartnerNotificationConditionInput
  ) {
    createPKPartnerNotification(input: $input, condition: $condition) {
      id
      notificationId
      partnerId
      createdAt
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      notification {
        id
        title
        description
        link
        contractId
        partners
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const updatePkPartnerNotification = /* GraphQL */ `
  mutation UpdatePkPartnerNotification(
    $input: UpdatePKPartnerNotificationInput!
    $condition: ModelPKPartnerNotificationConditionInput
  ) {
    updatePKPartnerNotification(input: $input, condition: $condition) {
      id
      notificationId
      partnerId
      createdAt
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      notification {
        id
        title
        description
        link
        contractId
        partners
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const deletePkPartnerNotification = /* GraphQL */ `
  mutation DeletePkPartnerNotification(
    $input: DeletePKPartnerNotificationInput!
    $condition: ModelPKPartnerNotificationConditionInput
  ) {
    deletePKPartnerNotification(input: $input, condition: $condition) {
      id
      notificationId
      partnerId
      createdAt
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      notification {
        id
        title
        description
        link
        contractId
        partners
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const createPkDatacallNotification = /* GraphQL */ `
  mutation CreatePkDatacallNotification(
    $input: CreatePKDatacallNotificationInput!
    $condition: ModelPKDatacallNotificationConditionInput
  ) {
    createPKDatacallNotification(input: $input, condition: $condition) {
      id
      title
      description
      documentLinkText
      link
      version
      dueDate
      contractId
      partners
      type
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      taskOrder {
        id
        name
        description
        contractId
        status
        taskOrderNumber
        folderId
        popStartDate
        popEndDate
        rfpReleaseDate
        proposalDueDate
        qandaDate
        publicFolderId
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePkDatacallNotification = /* GraphQL */ `
  mutation UpdatePkDatacallNotification(
    $input: UpdatePKDatacallNotificationInput!
    $condition: ModelPKDatacallNotificationConditionInput
  ) {
    updatePKDatacallNotification(input: $input, condition: $condition) {
      id
      title
      description
      documentLinkText
      link
      version
      dueDate
      contractId
      partners
      type
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      taskOrder {
        id
        name
        description
        contractId
        status
        taskOrderNumber
        folderId
        popStartDate
        popEndDate
        rfpReleaseDate
        proposalDueDate
        qandaDate
        publicFolderId
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePkDatacallNotification = /* GraphQL */ `
  mutation DeletePkDatacallNotification(
    $input: DeletePKDatacallNotificationInput!
    $condition: ModelPKDatacallNotificationConditionInput
  ) {
    deletePKDatacallNotification(input: $input, condition: $condition) {
      id
      title
      description
      documentLinkText
      link
      version
      dueDate
      contractId
      partners
      type
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      taskOrder {
        id
        name
        description
        contractId
        status
        taskOrderNumber
        folderId
        popStartDate
        popEndDate
        rfpReleaseDate
        proposalDueDate
        qandaDate
        publicFolderId
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPkDatacallNotificationPartner = /* GraphQL */ `
  mutation CreatePkDatacallNotificationPartner(
    $input: CreatePKDatacallNotificationPartnerInput!
    $condition: ModelPKDatacallNotificationPartnerConditionInput
  ) {
    createPKDatacallNotificationPartner(input: $input, condition: $condition) {
      id
      datacallNotificationId
      partnerId
      status
      copyFileURL
      createdBy
      createdAt
      updatedBy
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      datacallNotification {
        id
        title
        description
        documentLinkText
        link
        version
        dueDate
        contractId
        partners
        type
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const updatePkDatacallNotificationPartner = /* GraphQL */ `
  mutation UpdatePkDatacallNotificationPartner(
    $input: UpdatePKDatacallNotificationPartnerInput!
    $condition: ModelPKDatacallNotificationPartnerConditionInput
  ) {
    updatePKDatacallNotificationPartner(input: $input, condition: $condition) {
      id
      datacallNotificationId
      partnerId
      status
      copyFileURL
      createdBy
      createdAt
      updatedBy
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      datacallNotification {
        id
        title
        description
        documentLinkText
        link
        version
        dueDate
        contractId
        partners
        type
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const deletePkDatacallNotificationPartner = /* GraphQL */ `
  mutation DeletePkDatacallNotificationPartner(
    $input: DeletePKDatacallNotificationPartnerInput!
    $condition: ModelPKDatacallNotificationPartnerConditionInput
  ) {
    deletePKDatacallNotificationPartner(input: $input, condition: $condition) {
      id
      datacallNotificationId
      partnerId
      status
      copyFileURL
      createdBy
      createdAt
      updatedBy
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      datacallNotification {
        id
        title
        description
        documentLinkText
        link
        version
        dueDate
        contractId
        partners
        type
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
