import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CardActions from "@material-ui/core/CardActions";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0.5em",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.2),
      width: theme.spacing(50),
      height: theme.spacing(25),
    },
  },
}));

const Vehicle = ({ vehicle, onVehicleSelected }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [selectVehicle, setSelectVehicle] = useState({});

  const GeneralInfoIcon = (
    <Icon>
      <img alt="edit" src="/images/info.svg" height="100%" width="100%" />
    </Icon>
  );
  const SecuredInfoIcon = (
    <Icon>
      <img
        alt="edit"
        src="/images/secured-info.svg"
        height="100%"
        width="100%"
      />
    </Icon>
  );

  const ViewDatacallsIcon = (
    <Icon>
      <img
        alt="edit"
        src="/images/viewDatacall.svg"
        height="100%"
        width="100%"
        padding="below"
      />
    </Icon>
  );
  const taskOrder = (
    <img alt="edit" src="/images/task-order.png" height="60px" widtht="60px" />
  );

  useEffect(() => {
    setSelectVehicle(vehicle);
    setLoading(false);
  }, [selectVehicle]);

  return (
    <Paper className={classes.root} elevation={3}>
      {!loading && vehicle.type === "CONTRACT" && (
        <Grid
          container
          spacing={2}
          style={{ height: "10.0rem", width: "22rem" }}
        >
          <Grid item style={{ paddingLeft: "20px", paddingTop: "12px" }}>
            {taskOrder}
          </Grid>
          <Grid
            onClick={() => {
              onVehicleSelected(selectVehicle);
              onVehicleSelected(selectVehicle, "SHOW_TASKORDERS");
            }}
          >
            <Grid item container>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <Button
                    style={{
                      fontWeight: "500",
                      fontSize: "23px",
                      textAlign: "left",
                      textTransform: "none",
                      paddingTop: "5",
                      paddingBottom: "0",
                    }}
                  >
                    {selectVehicle.name}
                  </Button>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      textAlign: "left",
                      position: "relative",
                      left: "10px",
                    }}
                    color="textSecondary"
                    gutterBottom
                  >
                    {selectVehicle.agency}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr
            width="100%"
            style={{
              border: "0",
              clear: "both",
              display: "block",
              width: "100% ",
              backgroundColor: "#E4E7EB",
              height: "2.5px",
            }}
          ></hr>
          <Grid item style={{ marginTop: "-10%", padding: "15px" }}>
            <CardActions>
              <Button
                onClick={() => {
                  onVehicleSelected(selectVehicle);
                  onVehicleSelected(selectVehicle, "LOAD_GENERAL");
                }}
                size="12px"
                fontWeight="500"
                startIcon={GeneralInfoIcon}
                style={{
                  color: "#2A739D",
                  fontWeight: "bold",
                  fontSize: "10px",
                  display: "block",
                  textTransform: "none",
                }}
              >
                General Information
              </Button>

              <Button
                onClick={() => {
                  onVehicleSelected(selectVehicle, "LOAD_CONTRACT_DATACALL");
                }}
                size="12px"
                fontWeight="500"
                startIcon={ViewDatacallsIcon}
                style={{
                  fontWeight: "bold",
                  fontSize: "10px",
                  display: "block",
                  color: "#2A739D",
                  textTransform: "none",
                }}
              >
                Data Calls
              </Button>

              <Button
                onClick={() => {
                  onVehicleSelected(selectVehicle);
                  onVehicleSelected(selectVehicle, "LOAD_SECURED");
                }}
                size="12px"
                fontWeight="500"
                startIcon={SecuredInfoIcon}
                style={{
                  color: "#2A739D",
                  fontWeight: "bold",
                  fontSize: "10px",
                  display: "block",
                  textTransform: "none",
                }}
              >
                Secured Information
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default Vehicle;
