import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import Paper from "@material-ui/core/Paper";
import CallIcon from "@material-ui/icons/Call";
import CardActions from "@material-ui/core/CardActions";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0.5em",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.2),
      width: theme.spacing(45),
      height: theme.spacing(25),
    },
  },
}));

const TaskOrder = ({ taskOrder, contract, onTaskOrderSelected }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [selectTaskOrder, setSelectTaskOrder] = useState({});

  const GeneralInfoIcon = (
    <Icon>
      <img alt="edit" src="/images/info.svg" height="100%" weight="100%" />
    </Icon>
  );
  const SecuredInfoIcon = (
    <Icon>
      <img
        alt="edit"
        src="/images/secured-info.svg"
        height="100%"
        weight="100%"
      />
    </Icon>
  );

  const ViewDatacallsIcon = (
    <Icon>
      <img
        alt="edit"
        src="/images/viewDatacall.svg"
        height="100%"
        weight="100%"
      />
    </Icon>
  );

  const contractImg = (
    <img alt="edit" src="/images/contract.png" height="60px" widtht="60px" />
  );

  useEffect(() => {
    setSelectTaskOrder(taskOrder);
    setLoading(false);
  }, [selectTaskOrder]);
  //console.log("Contract", contract);
  return (
    <Paper className={classes.root} elevation={3}>
      {!loading && taskOrder.type === "TASKORDER" && (
        <Grid
          container
          spacing={2}
          style={{ height: "10.0rem", width: "22rem" }}
        >
          <Grid item>{contractImg}</Grid>

          <Grid item>
            <Typography
              style={{
                fontWeight: "500",
                fontSize: "23px",
                textAlign: "left",
                textTransform: "none",
                paddingTop: "5",
                paddingBottom: "0",
              }}
            >
              {selectTaskOrder.name}
            </Typography>
            <Typography
              style={{
                fontWeight: "500",
                fontSize: "16px",
                textAlign: "left",
                position: "relative",
              }}
              color="textSecondary"
              gutterBottom
            >
              {contract}
            </Typography>
          </Grid>
          <hr
            width="100%"
            style={{
              border: "0",
              clear: "both",
              display: "block",
              width: "100% ",
              backgroundColor: "#E4E7EB",
              height: "2.5px",
            }}
          ></hr>
          <Grid item style={{ marginTop: "-10%", padding: "15px" }}>
            <CardActions>
              <Button
                onClick={() => {
                  onTaskOrderSelected(selectTaskOrder);
                  onTaskOrderSelected(selectTaskOrder, "LOAD_GENERAL");
                }}
                size="12px"
                fontWeight="500"
                startIcon={GeneralInfoIcon}
                style={{
                  color: "#2A739D",
                  fontWeight: "bold",
                  fontSize: "10px",
                  display: "block",
                  textTransform: "none",
                }}
              >
                General Information
              </Button>
              <Button
                onClick={() => {
                  onTaskOrderSelected(
                    selectTaskOrder,
                    "LOAD_TASKORDER_DATACALL"
                  );
                }}
                size="12px"
                fontWeight="500"
                startIcon={ViewDatacallsIcon}
                style={{
                  color: "#2A739D",
                  fontWeight: "bold",
                  fontSize: "10px",
                  display: "block",
                  textTransform: "none",
                }}
              >
                Data Calls
              </Button>

              <Button
                onClick={() => {
                  onTaskOrderSelected(selectTaskOrder);
                  onTaskOrderSelected(selectTaskOrder, "LOAD_SECURED");
                }}
                size="12px"
                fontWeight="500"
                startIcon={SecuredInfoIcon}
                style={{
                  color: "#2A739D",
                  fontWeight: "bold",
                  fontSize: "10px",
                  display: "block",
                  textTransform: "none",
                }}
              >
                Secured Information
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default TaskOrder;
