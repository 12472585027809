import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import Container from "@material-ui/core/Container";
import AuthPage from "./pages/auth";
import PrivateRoute from "./components/layout/private-route";
import HomePage from "./pages/home";
import DatacallConfirmPage from "./pages/datacall-confirm";
import LogoutPage from "./pages/logout";
import './App.css';
import theme from "./components/layout/theme";

function App() {
  return (
      <ThemeProvider theme={theme}>
        <Container maxWidth='xl' style={{backgroundColor: "#EEF4F5"}}>
          <Router>
            <Switch>
              {/*<Redirect from="/" to="/auth" ></Redirect>*/}
              <Route exact={true} path="/">
                <AuthPage/>
              </Route>
              <Route exact={true} path="/auth">
                <AuthPage/>
              </Route>
              <PrivateRoute exact={true} path="/home">
                <HomePage/>
              </PrivateRoute>
              <Route exact={true} path="/logout">
                <LogoutPage/>
              </Route>
              <PrivateRoute exact={true} path="/datacall/:partnerDatacallNotificationId">
                <DatacallConfirmPage />
              </PrivateRoute>
            </Switch>
          </Router>
        </Container>
      </ThemeProvider>
  );
}

export default App;
