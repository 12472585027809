import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const DatacallTable = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [datacallItems, setDatacallItems] = useState(props.datacallList);

  useEffect(() => {
    //console.log("hi");
    setLoading(false);
    setDatacallItems(props.datacallList);
  }, [props.datacallList]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow key="header">
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">File Link</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            datacallItems &&
            datacallItems.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="left">
                  {row.datacallNotification.title}
                </TableCell>
                <TableCell align="left">
                  {row.datacallNotification.description}
                </TableCell>
                <TableCell align="left">
                  
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => {
                        window.open(row.copyFileURL);
                      }}
                    >
                      OPEN FILE
                    </Button>
                  
                </TableCell>
                <TableCell align="left">
                  {row.status === "SENT" && (
                    
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => {
                          props.onDatacallClick(row.id, row.status, false);
                        }}
                      >
                        Acknowledge
                      </Button>
                    
                  )}
                  {row.status === "ACKNOWLEDGED" && (
                    
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => {
                          props.onDatacallClick(row.id, row.status);
                        }}
                      >
                        Done
                      </Button>
                    
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DatacallTable;
