import AWSAppSyncClient from "aws-appsync";
import awsConfig from "./amplify-config";
import gql from "graphql-tag";
import { getToken } from "../auth-services";
import {
  getPkUser,
  pdatacallByDatacallNotificationPartner,
  partnerdatacallByPartner,
  taskOrderByContract,
} from "./graphql/queries";
import { updatePkDatacallNotificationPartner } from "./graphql/mutations";
//Amplify.configure(awsConfig);

if (!process.browser) {
  global.fetch = require("isomorphic-fetch");
}

const appSyncClient = new AWSAppSyncClient({
  url: awsConfig.aws_appsync_graphqlEndpoint,
  region: awsConfig.aws_appsync_region,
  auth: {
    type: awsConfig.aws_appsync_authenticationType,
    jwtToken: async () => await getToken(),
  },
  disableOffline: true,
});

export const getCurrentUser = async (input) => {
  const response = await appSyncClient.query({
    query: gql(getPkUser),
    variables: {
      id: input,
    },
  });

  return response;
};

// export const getDatacallByContract = async (input) => {
//     const response=  await appSyncClient.query({
//         query: gql(datacallByContract),
//         variables: {
//             contractId: input
//         }
//     });
//     ////console.log(response)
//     return response

// };

export const getPartnerDatacalls = async (partnerId) => {
  const response = await appSyncClient.query({
    query: gql(partnerdatacallByPartner),
    variables: {
      partnerId: partnerId,

      limit: 100,
      // userId: { eq: user }
    },
  });
  ////console.log(response)
  return response;
};

export const getTaskOrders = async (contractId) => {
  const response = await appSyncClient.query({
    query: gql(taskOrderByContract),
    variables: {
      contractId: contractId,
      filter: { status: { eq: "ACTIVE" } },
      limit: 100,
      // userId: { eq: user }
    },
  });
  ////console.log(response)
  return response;
};
export const getPartnerDatacallsForPartner = async (datacallId, partnerId) => {
  const response = await appSyncClient.query({
    query: gql(pdatacallByDatacallNotificationPartner),
    variables: {
      datacallNotificationId: datacallId,
      partnerId: { eq: partnerId },
      limit: 100,
      // userId: { eq: user }
    },
  });
  //console.log(response);
  return response;
};

export const updatePartnerDatacall = async (
  partnerDatacallNotificationId,
  status
) => {
  const response = await appSyncClient.mutate({
    mutation: gql(updatePkDatacallNotificationPartner),
    variables: {
      input: {
        id: partnerDatacallNotificationId,
        status: status,
      },
    },
  });
  //console.log('updatePartnerDatacall response')
  return response;
};
