/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listPkContracts = /* GraphQL */ `
  query ListPkContracts(
    $filter: ModelPKContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPKContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPkContract = /* GraphQL */ `
  query GetPkContract($id: ID!) {
    getPKContract(id: $id) {
      id
      name
      agency
      status
      contractNumber
      description
      popStartDate
      popEndDate
      driveId
      publicFolderId
      createdAt
      updatedAt
    }
  }
`;
export const listPkTaskOrders = /* GraphQL */ `
  query ListPkTaskOrders(
    $filter: ModelPKTaskOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPKTaskOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        contractId
        status
        taskOrderNumber
        folderId
        popStartDate
        popEndDate
        rfpReleaseDate
        proposalDueDate
        qandaDate
        publicFolderId
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPkTaskOrder = /* GraphQL */ `
  query GetPkTaskOrder($id: ID!) {
    getPKTaskOrder(id: $id) {
      id
      name
      description
      contractId
      status
      taskOrderNumber
      folderId
      popStartDate
      popEndDate
      rfpReleaseDate
      proposalDueDate
      qandaDate
      publicFolderId
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
    }
  }
`;
export const taskOrderByContract = /* GraphQL */ `
  query TaskOrderByContract(
    $contractId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPKTaskOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    taskOrderByContract(
      contractId: $contractId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        contractId
        status
        taskOrderNumber
        folderId
        popStartDate
        popEndDate
        rfpReleaseDate
        proposalDueDate
        qandaDate
        publicFolderId
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listPkPartners = /* GraphQL */ `
  query ListPkPartners(
    $filter: ModelPKPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPKPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPkPartner = /* GraphQL */ `
  query GetPkPartner($id: ID!) {
    getPKPartner(id: $id) {
      id
      name
      description
      status
      address
      primaryContact
      createdAt
      updatedAt
    }
  }
`;
export const listPkContractUserGroups = /* GraphQL */ `
  query ListPkContractUserGroups(
    $filter: ModelPKContractUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPKContractUserGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractId
        partnerId
        folderId
        taskOrderDrives {
          name
          folderId
        }
        email
        users
        status
        name
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroupMapping {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPkContractUserGroup = /* GraphQL */ `
  query GetPkContractUserGroup($id: ID!) {
    getPKContractUserGroup(id: $id) {
      id
      contractId
      partnerId
      folderId
      taskOrderDrives {
        name
        folderId
      }
      email
      users
      status
      name
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      userGroupMapping {
        items {
          id
          contractUserGroupId
          userId
          status
          createdAt
          updatedAt
          contractUserGroup {
            id
            contractId
            partnerId
            folderId
            taskOrderDrives {
              name
              folderId
            }
            email
            users
            status
            name
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroupMapping {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          user {
            id
            name
            email
            secondaryEmail
            status
            deviceTokens
            partnerKonnectRoles
            phoneNumber
            createdAt
            updatedAt
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroups {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const contractUserGroupByContract = /* GraphQL */ `
  query ContractUserGroupByContract(
    $contractId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPKContractUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractUserGroupByContract(
      contractId: $contractId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractId
        partnerId
        folderId
        taskOrderDrives {
          name
          folderId
        }
        email
        users
        status
        name
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroupMapping {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const contractUserGroupByPartner = /* GraphQL */ `
  query ContractUserGroupByPartner(
    $partnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPKContractUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractUserGroupByPartner(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractId
        partnerId
        folderId
        taskOrderDrives {
          name
          folderId
        }
        email
        users
        status
        name
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroupMapping {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const contractUserGroupByContractPartner = /* GraphQL */ `
  query ContractUserGroupByContractPartner(
    $contractId: ID
    $partnerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPKContractUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractUserGroupByContractPartner(
      contractId: $contractId
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractId
        partnerId
        folderId
        taskOrderDrives {
          name
          folderId
        }
        email
        users
        status
        name
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroupMapping {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPkUserGroupMapping = /* GraphQL */ `
  query GetPkUserGroupMapping($id: ID!) {
    getPKUserGroupMapping(id: $id) {
      id
      contractUserGroupId
      userId
      status
      createdAt
      updatedAt
      contractUserGroup {
        id
        contractId
        partnerId
        folderId
        taskOrderDrives {
          name
          folderId
        }
        email
        users
        status
        name
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroupMapping {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      user {
        id
        name
        email
        secondaryEmail
        status
        deviceTokens
        partnerKonnectRoles
        phoneNumber
        createdAt
        updatedAt
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroups {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listPkUserGroupMappings = /* GraphQL */ `
  query ListPkUserGroupMappings(
    $filter: ModelPKUserGroupMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPKUserGroupMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractUserGroupId
        userId
        status
        createdAt
        updatedAt
        contractUserGroup {
          id
          contractId
          partnerId
          folderId
          taskOrderDrives {
            name
            folderId
          }
          email
          users
          status
          name
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
          partner {
            id
            name
            description
            status
            address
            primaryContact
            createdAt
            updatedAt
          }
          userGroupMapping {
            items {
              id
              contractUserGroupId
              userId
              status
              createdAt
              updatedAt
              contractUserGroup {
                id
                contractId
                partnerId
                folderId
                email
                users
                status
                name
                createdAt
                updatedAt
              }
              user {
                id
                name
                email
                secondaryEmail
                status
                deviceTokens
                partnerKonnectRoles
                phoneNumber
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
        user {
          id
          name
          email
          secondaryEmail
          status
          deviceTokens
          partnerKonnectRoles
          phoneNumber
          createdAt
          updatedAt
          partner {
            id
            name
            description
            status
            address
            primaryContact
            createdAt
            updatedAt
          }
          userGroups {
            items {
              id
              contractUserGroupId
              userId
              status
              createdAt
              updatedAt
              contractUserGroup {
                id
                contractId
                partnerId
                folderId
                email
                users
                status
                name
                createdAt
                updatedAt
              }
              user {
                id
                name
                email
                secondaryEmail
                status
                deviceTokens
                partnerKonnectRoles
                phoneNumber
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const userGroupMappingByUserGroup = /* GraphQL */ `
  query UserGroupMappingByUserGroup(
    $contractUserGroupId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPKUserGroupMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userGroupMappingByUserGroup(
      contractUserGroupId: $contractUserGroupId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractUserGroupId
        userId
        status
        createdAt
        updatedAt
        contractUserGroup {
          id
          contractId
          partnerId
          folderId
          taskOrderDrives {
            name
            folderId
          }
          email
          users
          status
          name
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
          partner {
            id
            name
            description
            status
            address
            primaryContact
            createdAt
            updatedAt
          }
          userGroupMapping {
            items {
              id
              contractUserGroupId
              userId
              status
              createdAt
              updatedAt
              contractUserGroup {
                id
                contractId
                partnerId
                folderId
                email
                users
                status
                name
                createdAt
                updatedAt
              }
              user {
                id
                name
                email
                secondaryEmail
                status
                deviceTokens
                partnerKonnectRoles
                phoneNumber
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
        user {
          id
          name
          email
          secondaryEmail
          status
          deviceTokens
          partnerKonnectRoles
          phoneNumber
          createdAt
          updatedAt
          partner {
            id
            name
            description
            status
            address
            primaryContact
            createdAt
            updatedAt
          }
          userGroups {
            items {
              id
              contractUserGroupId
              userId
              status
              createdAt
              updatedAt
              contractUserGroup {
                id
                contractId
                partnerId
                folderId
                email
                users
                status
                name
                createdAt
                updatedAt
              }
              user {
                id
                name
                email
                secondaryEmail
                status
                deviceTokens
                partnerKonnectRoles
                phoneNumber
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const userGroupMappingByUser = /* GraphQL */ `
  query UserGroupMappingByUser(
    $userId: ID
    $contractUserGroupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPKUserGroupMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userGroupMappingByUser(
      userId: $userId
      contractUserGroupId: $contractUserGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractUserGroupId
        userId
        status
        createdAt
        updatedAt
        contractUserGroup {
          id
          contractId
          partnerId
          folderId
          taskOrderDrives {
            name
            folderId
          }
          email
          users
          status
          name
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
          partner {
            id
            name
            description
            status
            address
            primaryContact
            createdAt
            updatedAt
          }
          userGroupMapping {
            items {
              id
              contractUserGroupId
              userId
              status
              createdAt
              updatedAt
              contractUserGroup {
                id
                contractId
                partnerId
                folderId
                email
                users
                status
                name
                createdAt
                updatedAt
              }
              user {
                id
                name
                email
                secondaryEmail
                status
                deviceTokens
                partnerKonnectRoles
                phoneNumber
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
        user {
          id
          name
          email
          secondaryEmail
          status
          deviceTokens
          partnerKonnectRoles
          phoneNumber
          createdAt
          updatedAt
          partner {
            id
            name
            description
            status
            address
            primaryContact
            createdAt
            updatedAt
          }
          userGroups {
            items {
              id
              contractUserGroupId
              userId
              status
              createdAt
              updatedAt
              contractUserGroup {
                id
                contractId
                partnerId
                folderId
                email
                users
                status
                name
                createdAt
                updatedAt
              }
              user {
                id
                name
                email
                secondaryEmail
                status
                deviceTokens
                partnerKonnectRoles
                phoneNumber
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPkUsers = /* GraphQL */ `
  query ListPkUsers(
    $filter: ModelPKUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPKUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        secondaryEmail
        status
        deviceTokens
        partnerKonnectRoles
        phoneNumber
        createdAt
        updatedAt
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        userGroups {
          items {
            id
            contractUserGroupId
            userId
            status
            createdAt
            updatedAt
            contractUserGroup {
              id
              contractId
              partnerId
              folderId
              taskOrderDrives {
                name
                folderId
              }
              email
              users
              status
              name
              createdAt
              updatedAt
              contract {
                id
                name
                agency
                status
                contractNumber
                description
                popStartDate
                popEndDate
                driveId
                publicFolderId
                createdAt
                updatedAt
              }
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroupMapping {
                nextToken
              }
            }
            user {
              id
              name
              email
              secondaryEmail
              status
              deviceTokens
              partnerKonnectRoles
              phoneNumber
              createdAt
              updatedAt
              partner {
                id
                name
                description
                status
                address
                primaryContact
                createdAt
                updatedAt
              }
              userGroups {
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPkUser = /* GraphQL */ `
  query GetPkUser($id: ID!) {
    getPKUser(id: $id) {
      id
      name
      email
      secondaryEmail
      status
      deviceTokens
      partnerKonnectRoles
      phoneNumber
      createdAt
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      userGroups(filter: {status: {eq: ACTIVE}}) {
        items {
          id
          contractUserGroupId
          userId
          status
          createdAt
          updatedAt
          contractUserGroup {
            id
            contractId
            partnerId
            folderId
            taskOrderDrives {
              name
              folderId
            }
            email
            users
            status
            name
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroupMapping {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          user {
            id
            name
            email
            secondaryEmail
            status
            deviceTokens
            partnerKonnectRoles
            phoneNumber
            createdAt
            updatedAt
            partner {
              id
              name
              description
              status
              address
              primaryContact
              createdAt
              updatedAt
            }
            userGroups {
              items {
                id
                contractUserGroupId
                userId
                status
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listPkNotifications = /* GraphQL */ `
  query ListPkNotifications(
    $filter: ModelPKNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPKNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        link
        contractId
        partners
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getPkNotification = /* GraphQL */ `
  query GetPkNotification($id: ID!) {
    getPKNotification(id: $id) {
      id
      title
      description
      link
      contractId
      partners
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      taskOrder {
        id
        name
        description
        contractId
        status
        taskOrderNumber
        folderId
        popStartDate
        popEndDate
        rfpReleaseDate
        proposalDueDate
        qandaDate
        publicFolderId
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const getPkPartnerNotification = /* GraphQL */ `
  query GetPkPartnerNotification($id: ID!) {
    getPKPartnerNotification(id: $id) {
      id
      notificationId
      partnerId
      createdAt
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      notification {
        id
        title
        description
        link
        contractId
        partners
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const listPkPartnerNotifications = /* GraphQL */ `
  query ListPkPartnerNotifications(
    $filter: ModelPKPartnerNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPKPartnerNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notificationId
        partnerId
        createdAt
        updatedAt
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        notification {
          id
          title
          description
          link
          contractId
          partners
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
          taskOrder {
            id
            name
            description
            contractId
            status
            taskOrderNumber
            folderId
            popStartDate
            popEndDate
            rfpReleaseDate
            proposalDueDate
            qandaDate
            publicFolderId
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const partnernotificationByPartner = /* GraphQL */ `
  query PartnernotificationByPartner(
    $partnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPKPartnerNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnernotificationByPartner(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notificationId
        partnerId
        createdAt
        updatedAt
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        notification {
          id
          title
          description
          link
          contractId
          partners
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
          taskOrder {
            id
            name
            description
            contractId
            status
            taskOrderNumber
            folderId
            popStartDate
            popEndDate
            rfpReleaseDate
            proposalDueDate
            qandaDate
            publicFolderId
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listPkDatacallNotifications = /* GraphQL */ `
  query ListPkDatacallNotifications(
    $filter: ModelPKDatacallNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPKDatacallNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        documentLinkText
        link
        version
        dueDate
        contractId
        partners
        type
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getPkDatacallNotification = /* GraphQL */ `
  query GetPkDatacallNotification($id: ID!) {
    getPKDatacallNotification(id: $id) {
      id
      title
      description
      documentLinkText
      link
      version
      dueDate
      contractId
      partners
      type
      createdAt
      updatedAt
      contract {
        id
        name
        agency
        status
        contractNumber
        description
        popStartDate
        popEndDate
        driveId
        publicFolderId
        createdAt
        updatedAt
      }
      taskOrder {
        id
        name
        description
        contractId
        status
        taskOrderNumber
        folderId
        popStartDate
        popEndDate
        rfpReleaseDate
        proposalDueDate
        qandaDate
        publicFolderId
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const datacallNotificationByContract = /* GraphQL */ `
  query DatacallNotificationByContract(
    $contractId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPKDatacallNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    datacallNotificationByContract(
      contractId: $contractId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        documentLinkText
        link
        version
        dueDate
        contractId
        partners
        type
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getPkDatacallNotificationPartner = /* GraphQL */ `
  query GetPkDatacallNotificationPartner($id: ID!) {
    getPKDatacallNotificationPartner(id: $id) {
      id
      datacallNotificationId
      partnerId
      status
      copyFileURL
      createdBy
      createdAt
      updatedBy
      updatedAt
      partner {
        id
        name
        description
        status
        address
        primaryContact
        createdAt
        updatedAt
      }
      datacallNotification {
        id
        title
        description
        documentLinkText
        link
        version
        dueDate
        contractId
        partners
        type
        createdAt
        updatedAt
        contract {
          id
          name
          agency
          status
          contractNumber
          description
          popStartDate
          popEndDate
          driveId
          publicFolderId
          createdAt
          updatedAt
        }
        taskOrder {
          id
          name
          description
          contractId
          status
          taskOrderNumber
          folderId
          popStartDate
          popEndDate
          rfpReleaseDate
          proposalDueDate
          qandaDate
          publicFolderId
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const listPkDatacallNotificationPartners = /* GraphQL */ `
  query ListPkDatacallNotificationPartners(
    $filter: ModelPKDatacallNotificationPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPKDatacallNotificationPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datacallNotificationId
        partnerId
        status
        copyFileURL
        createdBy
        createdAt
        updatedBy
        updatedAt
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        datacallNotification {
          id
          title
          description
          documentLinkText
          link
          version
          dueDate
          contractId
          partners
          type
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
          taskOrder {
            id
            name
            description
            contractId
            status
            taskOrderNumber
            folderId
            popStartDate
            popEndDate
            rfpReleaseDate
            proposalDueDate
            qandaDate
            publicFolderId
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const pdatacallByDatacallNotificationPartner = /* GraphQL */ `
  query PdatacallByDatacallNotificationPartner(
    $datacallNotificationId: ID
    $partnerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPKDatacallNotificationPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pdatacallByDatacallNotificationPartner(
      datacallNotificationId: $datacallNotificationId
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datacallNotificationId
        partnerId
        status
        copyFileURL
        createdBy
        createdAt
        updatedBy
        updatedAt
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        datacallNotification {
          id
          title
          description
          documentLinkText
          link
          version
          dueDate
          contractId
          partners
          type
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
          taskOrder {
            id
            name
            description
            contractId
            status
            taskOrderNumber
            folderId
            popStartDate
            popEndDate
            rfpReleaseDate
            proposalDueDate
            qandaDate
            publicFolderId
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const partnerdatacallByPartner = /* GraphQL */ `
  query PartnerdatacallByPartner(
    $partnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPKDatacallNotificationPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerdatacallByPartner(
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datacallNotificationId
        partnerId
        status
        copyFileURL
        createdBy
        createdAt
        updatedBy
        updatedAt
        partner {
          id
          name
          description
          status
          address
          primaryContact
          createdAt
          updatedAt
        }
        datacallNotification {
          id
          title
          description
          documentLinkText
          link
          version
          dueDate
          contractId
          partners
          type
          createdAt
          updatedAt
          contract {
            id
            name
            agency
            status
            contractNumber
            description
            popStartDate
            popEndDate
            driveId
            publicFolderId
            createdAt
            updatedAt
          }
          taskOrder {
            id
            name
            description
            contractId
            status
            taskOrderNumber
            folderId
            popStartDate
            popEndDate
            rfpReleaseDate
            proposalDueDate
            qandaDate
            publicFolderId
            createdAt
            updatedAt
            contract {
              id
              name
              agency
              status
              contractNumber
              description
              popStartDate
              popEndDate
              driveId
              publicFolderId
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
    }
  }
`;
