import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import { updatePartnerDatacall } from "../../services/api/client";
import TaskOrder from "../vehicles/task-order";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    margin: "0.75rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fabButtons: {
    background: theme.palette.primary.light,
    color: theme.palette.background.default,
  },
  paper: {
    padding: 0,
    margin: "auto",
    width: "100%",
  },
  image: {
    height: "100%",
    backgroundColor: "#411932",
    borderWidth: "5px",
    borderStyle: "none",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const Notification = (props) => {
  const classes = useStyles();
  const cardRef = React.createRef();

  const {
    title,
    description,
    type,
    createdAt,
    dueDate,
    version,
  } = props.notificationDatacallJson.datacallNotification;
  let status = props.notificationDatacallJson.status;
  let id = props.notificationDatacallJson.id;
  let displayDueDate;

  let contractName =
    props.notificationDatacallJson.datacallNotification.contract.name;
  let taskOrderName = props.notificationDatacallJson.datacallNotification
    .taskOrder
    ? ": " + props.notificationDatacallJson.datacallNotification.taskOrder.name
    : "";

  let link =
    type === "DATACALL"
      ? props.notificationDatacallJson.copyFileURL
      : props.notificationDatacallJson.datacallNotification.link;

  const [datacallDate, setDatacallDate] = useState();

  let eventDateMoment = moment(createdAt);
  const [dateToDisplay, setDateToDisplay] = useState(
    eventDateMoment.format("MMM DD, hh:mm A").toUpperCase()
  );

  let notificationImage = "/images/notification.svg";
  let datacallImage = "/images/datacall.svg";

  let desc = description,
    isDescLonger = false;
  if (description.length > 180) {
    desc = description.substring(0, 180);
    isDescLonger = true;
  }

  const [isExpanded, setIsExpanded] = useState(false);
  const [loading] = useState(true);
  const [cardHeight, setCardHeight] = useState({ height: "4rem" });

  useEffect(() => {
    if (type === "DATACALL") {
      displayDueDate = moment(dueDate);
      setDatacallDate(displayDueDate.format("MMM, DD").toUpperCase());
    }
  }, [props.notificationDatacallJson]);

  useEffect(() => {
    let nm = moment(new Date());
    let diffInHours = eventDateMoment.diff(nm, "hours");
    if (diffInHours >= 0 && diffInHours <= 12) {
      const interval = setInterval(() => {
        setDateToDisplay(eventDateMoment.fromNow());
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [eventDateMoment]);

  const notificationClickAction = () => {
    window.open(link);
  };

  const showPopup = (event) => {
    if (isDescLonger) {
      setCardHeight({ height: "6rem" });
      setIsExpanded(true);
    }
  };

  const handleClose = () => {
    if (isDescLonger) {
      setCardHeight({ height: "3.0rem" });
      setIsExpanded(false);
    }
  };

  return (
    <Fragment>
      <Grow
        in={loading}
        {...(loading ? { timeout: props.cardIndex * 500 } : {})}
      >
        <Paper elevation={4} className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <ButtonBase className={classes.image}>
                {type === "DATACALL" && (
                  <img
                    className={classes.img}
                    alt="complex"
                    src={datacallImage}
                  />
                )}
                {type === "NOTIFICATION" && (
                  <img
                    className={classes.img}
                    alt="complex"
                    src={notificationImage}
                  />
                )}
              </ButtonBase>
            </Grid>
            <Grid item xs={10}>
              <div style={{ padding: "0.5em", paddingLeft: "2em" }}>
                <div style={{ width: "100%" }}>
                  <Grid container spacing={0}>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: "500",
                          fontSize: "23.5px",
                        }}
                      >
                        {title}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        {contractName}
                        {taskOrderName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "right",
                          paddingRight: "10px",
                        }}
                      >
                        <Typography variant="caption" style={{}}>
                          {type === "NOTIFICATION"
                            ? dateToDisplay
                            : datacallDate}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <Collapse in={isExpanded} collapsedHeight={"5em"}>
                  <div
                    style={cardHeight}
                    ref={cardRef}
                    onMouseEnter={showPopup}
                    onMouseLeave={handleClose}
                  >
                    {!isExpanded && (
                      <Typography variant="subtitle2" color="textSecondary">
                        {desc}
                        {isDescLonger && (
                          <IconButton
                            color="secondary"
                            aria-label="Go to Apps Home"
                            size="small"
                            component="span"
                            onClick={showPopup}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        )}
                      </Typography>
                    )}
                    {isExpanded && (
                      <Typography variant="subtitle2" color="textSecondary">
                        {description}
                      </Typography>
                    )}
                  </div>
                </Collapse>

                <div style={{ width: "100%" }}>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      {type === "DATACALL" && (
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          style={{
                            textAlign: "left",
                            paddingTop: "4px",
                          }}
                        >
                          Version: {version}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "right",
                          paddingRight: "0.5em",
                        }}
                      >
                        <Button
                          key={"button-notif-"}
                          color="primary"
                          size="small"
                          onClick={() => notificationClickAction()}
                        >
                          View Details
                        </Button>
                        {type === "DATACALL" && status === "SENT" && (
                          <Button
                            key={"button-notifc-"}
                            color="primary"
                            size="small"
                            onClick={() =>
                              props.onDatacallClick(id, status, true)
                            }
                          >
                            Acknowledge
                          </Button>
                        )}
                        {type === "DATACALL" && status === "ACKNOWLEDGED" && (
                          <Button
                            key={"button-notifca-"}
                            color="primary"
                            size="small"
                            onClick={() => props.onDatacallClick(id, status)}
                          >
                            Mark as Complete
                          </Button>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grow>
    </Fragment>
  );
};

export default Notification;
