/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ba02e309-dafb-4f35-8c07-6f7648aed72d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PACquPZbP",
    "aws_user_pools_web_client_id": "6c6c003ldpovi9ke45u9m13j30",
    "oauth": {
        "domain": "partner-konnect-dev-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://partner.karsun-llc.com/auth",
        "redirectSignOut": "https://partner.karsun-llc.com/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://usedof3st5covfat6xnhif5wo4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-tqari3e5ovculpg3k3iccbuddy"
};


export default awsmobile;
