import React, {Fragment, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '16em'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: '15em',
    }
}));

const ProfileCard = ({name, picture, onProfileSelected}) => {

    const classes = useStyles();

    return (
        <Fragment>
            <Card className={classes.root}>
                <CardMedia
                    className={classes.cover}
                    image={picture}
                    title={name}
                />
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h6" variant="h6">
                            {name}
                        </Typography>
                        {/* <Button color="primary">View / Edit Profile</Button> */}
                        <br/>
                        {/* <Button href="https://www.appsheet.com/start/079fec02-ce82-44e0-a59d-d6e5e7db39b4#appName=PartnerKonnectMain-1396489&group=%5B%5D&page=fastTable&sort=%5B%5D&table=Vehicle&view=All+Vehicles"  target="_blank" color="primary">Manage</Button> */}
                    </CardContent>
                </div>
            </Card>

        </Fragment>
    );
}

export default ProfileCard;
