import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import KonnectHeader from "../components/layout/konnect-header";
import CardsContainer from "../components/notifications/cards-container";
import ToastNotification from "../components/notifications/toast-notification";
import VehicleList from "../components/vehicles/vehicles-list";
import DatacallTable from "../components/datacalls/datacall";
import _ from "lodash";
import { currentAuthenticatedUser } from "../services/auth-services";

import {
  getCurrentUser,
  getTaskOrders,
  getPartnerDatacalls,
  updatePartnerDatacall,
} from "../services/api/client";

import GooglePicker from "react-google-picker";
import { Storage } from "aws-amplify";
import { taskOrderByContract } from "../services/api/graphql/queries";
class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // currentUser: {name: 'Shan Palanivelu', picture: undefined},
      allNotifications: [],
      currentUser: undefined,
      contractList: [],
      datacallList: [],
      notificationList: [],
      selectVehicleDatacallList: [],
      showContractName: [],
      showContract: false,
      showNewNotification: false,
      showUpload: false,
      showDrive: false,
      showDatacall: false,
      showNotifications: false,

      newNotification: null,
      driveURL: [],

      change: false,

      partnerInfo: {},
    };
    this.handleVehicleSelection = this.handleVehicleSelection.bind(this);
  }

  async componentDidMount() {
    /* const currentUser = await currentAuthenticatedUser();
        //console.log(currentUser)
        this.setState({currentUser: currentUser});
        const userRoles = currentUser.roles;*/

    const currentUser = await currentAuthenticatedUser();
    this.setState({ currentUser: currentUser });

    //getCurrentUser has to be replaced with authenticated user
    const userVal = await getCurrentUser(currentUser.email);

    if (userVal.data.getPKUser != null) {
      this.setState({
        partnerInfo: userVal.data.getPKUser.partner,
        contractList: userVal.data.getPKUser.userGroups.items.map((item) => {
          return {
            id: item.id,
            contractId: item.contractUserGroup.contract.id,
            agency: item.contractUserGroup.contract.agency,
            name: item.contractUserGroup.contract.name,
            publicFolderId: item.contractUserGroup.contract.publicFolderId,
            folderId: item.contractUserGroup.folderId,
            taskOrderDrives: item.contractUserGroup.taskOrderDrives,
            type: "CONTRACT",
          };
        }),
        showContract: userVal.data.getPKUser.userGroups.items.length > 0,
      });
    }

    if (this.state.showContract) {
      const taskOrderList = await this.categorizeTaskOrdersByContract(
        this.state.contractList
      );

      const datacallNotificationList = await getPartnerDatacalls(
        this.state.partnerInfo.id
      );

      let {
        datacallList,
        notificationList,
      } = await this.categorizeDatacallsAndNotifications(
        datacallNotificationList.data.partnerdatacallByPartner.items
      );

      const datacallListByContract = await this.categorizeDatacallsByContract(
        this.state.contractList,
        datacallList
      );

      const datacallListByTaskOrder = await this.categorizeDatacallsByTaskOrder(
        taskOrderList,
        datacallList
      );

      this.setState({
        taskOrderList: taskOrderList,
        datacallList: datacallList,
        notificationList: notificationList,
        datacallListByContract: datacallListByContract,
        datacallListByTaskOrder: datacallListByTaskOrder,
        showNotifications: true,
        driveURL:
          "https://drive.google.com/drive/u/0/folders/" +
          this.state.contractList[0].publicFolderId,
      });
    }
  }

  /*componentWillUnmount() {
        this.eventsSubscription.unsubscribe();
    }*/

  //onClick() of drive action passing vehicle data from vehicle functional component
  handleVehicleSelection(vehicle, action) {
    if (action === "LOAD_GENERAL") {
      this.setState({
        driveURL:
          "https://drive.google.com/drive/u/0/folders/" +
          vehicle.publicFolderId,
        showDatacall: false,
        showDrive: true,
        showUpload: false,
      });
      window.open(
        "https://drive.google.com/drive/u/0/folders/" + vehicle.publicFolderId
      );
    }
    if (action === "LOAD_SECURED") {
      this.setState({
        driveURL:
          "https://drive.google.com/drive/u/0/folders/" + vehicle.folderId,
        showDatacall: false,
        showDrive: true,
        showUpload: false,
      });

      window.open(
        "https://drive.google.com/drive/u/0/folders/" + vehicle.folderId
      );
    }
    if (action === "LOAD_CONTRACT_DATACALL") {
      //console.log(this.state.datacallListByContract)
      //console.log(vehicle.contractId)
      this.setState({
        showDatacall: true,
        showDrive: false,
        showUpload: false,
        selectVehicleDatacallList: this.state.datacallListByContract[
          vehicle.contractId
        ],
      });
    }

    if (action === "LOAD_TASKORDER_DATACALL") {
      this.setState({
        showDatacall: true,
        showDrive: false,
        showUpload: false,
        selectVehicleDatacallList: this.state.datacallListByTaskOrder[
          vehicle.id
        ],
      });
    }
    if (action === "SHOW_TASKORDERS") {
      this.setState({
        showUpload: false,
        showDatacall: false,
        showDrive: false,
        showTaskOrders: true,
        selectedVehicleTaskOrders: this.state.taskOrderList[vehicle.contractId],
        showContractName: vehicle.name,
      });
    }
  }

  // isNotification to identify call from vehicle or notification
  handleDatacall = async (
    partnerDatacallNotificationId,
    status,
    isNotification
  ) => {
    let statusSent = "";
    let change = false;
    if (status === "SENT") {
      statusSent = "ACKNOWLEDGED";
    } else if (status === "ACKNOWLEDGED") {
      statusSent = "RECEIVED";
    }
    this.setState({ loading: true });
    let result = await updatePartnerDatacall(
      partnerDatacallNotificationId,
      statusSent
    );

    if (isNotification) {
      let updatecallList = this.state.datacallList;
      let data = updatecallList.map((val) => {
        if (val.id === partnerDatacallNotificationId) {
          val.status = result.data.updatePKDatacallNotificationPartner.status;
        }
        return val;
      });
      this.setState({
        datacallList: data,
      });
    } else {
      let updatecallList = this.state.selectVehicleDatacallList;
      let data = updatecallList.map((val) => {
        if (val.id === partnerDatacallNotificationId) {
          val.status = result.data.updatePKDatacallNotificationPartner.status;
        }
        return val;
      });
      this.setState({
        datacallList: data,
      });
    }
  };

  categorizeTaskOrdersByContract = async (contractList) => {
    let taskOrderListByContract = {};

    //await contractList.map(async(contractDetails)=>{
    for (const contractDetails of contractList) {
      taskOrderListByContract[contractDetails.contractId] = [];

      let taskOrders = await getTaskOrders(contractDetails.contractId);

      taskOrderListByContract[
        contractDetails.contractId
      ] = taskOrders.data.taskOrderByContract.items.map((item) => {
        let taskOrderPartnerFolder = contractDetails.taskOrderDrives.find(
          (n) => n.name === item.name
        );

        return {
          id: item.id,
          name: item.name,
          type: "TASKORDER",
          publicFolderId: item.publicFolderId,
          folderId: taskOrderPartnerFolder.folderId,
        };
      });
    }

    return taskOrderListByContract;
  };

  categorizeDatacallsAndNotifications = async (datacallNotificationList) => {
    let datacallList = [],
      notificationList = [];
    datacallNotificationList.map(async (datacallNotification) => {
      if (datacallNotification.datacallNotification.type == "DATACALL") {
        datacallList.push(datacallNotification);
      } else {
        notificationList.push(datacallNotification);
      }
    });

    return { datacallList: datacallList, notificationList: notificationList };
  };

  categorizeDatacallsByContract = async (contractList, datacallList) => {
    let data = _.chain(datacallList)
      .groupBy("datacallNotification.contract.id")
      .mapValues((val) => {
        return _.filter(val, (val2) => {
          if (!val2.datacallNotification.taskOrder) return val2;
        });
      })
      .omit("undefined")
      .value();
    return data;
  };

  categorizeDatacallsByTaskOrder = async (taskOrderList, datacallList) => {
    let data = _.chain(datacallList)
      .groupBy("datacallNotification.taskOrder.id")
      .mapValues((val) => {
        return _.forEach(val, (val2) => {
          return val2;
        });
      })
      .omit("undefined")
      .value();
    return data;
  };

  render() {
    //console.log("Show contract Name", this.state.showContractName);
    if (!this.state.currentUser || this.state.currentUser === null) {
      return null;
    }
    return (
      <Fragment>
        <ToastNotification
          notification={this.state.newNotification}
          showToast={this.state.showNewNotification}
          handleClose={() => this.setState({ showNewNotification: false })}
        ></ToastNotification>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <KonnectHeader
              name={this.state.currentUser.name}
              picture={this.state.currentUser.picture}
            ></KonnectHeader>
          </Grid>

          <Grid item xl={4} lg={4} md={12}>
            {this.state.showNotifications && (
              <CardsContainer
                change={this.state.change}
                status={this.state.status}
                notifications={this.state.notificationList}
                datacallList={this.state.datacallList}
                onDatacallClick={this.handleDatacall}
              ></CardsContainer>
            )}
          </Grid>
          <Grid item xl={8} lg={8} md={12}>
            <Box style={{ width: "100%", marginBottom: "1rem" }}>
              {this.state.showContract && (
                <VehicleList
                  type="CONTRACT"
                  vehicleInfo={this.state.contractList}
                  onVehicleSelected={this.handleVehicleSelection}
                />
              )}
            </Box>
            <Box style={{ width: "100%", marginBottom: "1rem" }}>
              {this.state.showTaskOrders && (
                <VehicleList
                  type="TASKORDER"
                  vehicleInfo={this.state.selectedVehicleTaskOrders}
                  contractName={this.state.showContractName}
                  onVehicleSelected={this.handleVehicleSelection}
                />
              )}
            </Box>

            <Box
              style={{
                width: "100%",
                height: "60vh",
                border: "2px",
                borderColor: "black",
                marginTop: "1rem",
              }}
            >
              {this.state.showContract && this.state.showDatacall && (
                <DatacallTable
                  datacallList={this.state.selectVehicleDatacallList}
                  onDatacallClick={this.handleDatacall}
                />
              )}
              {/* {this.state.showUpload && (
                <UploadButton onUpload={this.handleUpload}></UploadButton>
              )} */}
            </Box>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withRouter(HomePage);
