import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#4A154B',
            light: '#6c3593',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            contrastText: '#EEF4F5',
        },
        secondary: {
            main: "#536dfe",
            contrastText: '#EEF4F5',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        background: {
            default: "#EEF4F5"
        },
        success: {
            main: "#4caf50"
        },
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: [
            'Lato',
            'Roboto',
        ].join(','),
    }
});

export default theme;