import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import Vehicle from "./vehicle";
import TaskOrder from "./task-order";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    marginTop: "1em",
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

const VehicleList = ({
  vehicleInfo,
  contractName,
  onVehicleSelected,
  type,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [selectVehicle, setSelectVehicle] = useState({});

  useEffect(() => {
    setSelectVehicle(vehicleInfo);
    setLoading(false);
  }, [selectVehicle]);
  //console.log("Vehicle list", vehicleInfo);
  return (
    <div className={classes.root}>
      {!loading && (
        <>
          <GridList className={classes.gridList} cols={10}>
            {vehicleInfo.map((vehicle) => (
              <>
                <GridListTile key={vehicle.id}>
                  {type === "CONTRACT" && (
                    <Vehicle
                      vehicle={vehicle}
                      onVehicleSelected={onVehicleSelected}
                    />
                  )}
                  {type === "TASKORDER" && (
                    <TaskOrder
                      taskOrder={vehicle}
                      contract={contractName}
                      onTaskOrderSelected={onVehicleSelected}
                    />
                  )}
                </GridListTile>
              </>
            ))}
          </GridList>
        </>
      )}
    </div>
  );
};

export default VehicleList;
