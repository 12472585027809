import React, { Component, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { currentAuthenticatedUser } from "../../services/auth-services";
import { useLocation } from "react-router-dom";
import { setItem, getItem } from "../../services/local-storage";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const isAdminPage = location.pathname.indexOf("manage") > 0;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    setItem("pathname", location.pathname);
    const checkAuth = async () => {
      const currentUser = await currentAuthenticatedUser();
      setCurrentUser(currentUser);
      setIsLoggedIn(currentUser && currentUser !== null);
      setIsAdmin(
        currentUser &&
          currentUser.roles &&
          (currentUser.roles.indexOf("ADMIN") >= 0 ||
            currentUser.roles.indexOf("ACADEMY_ADMIN") >= 0)
      );
      setIsLoaded(true);
    };
    checkAuth();
  }, []);

  if (!isLoaded) {
    return null;
  }

  if (isLoaded && !isLoggedIn) {
    return <Redirect to={{ pathname: "/auth" }}></Redirect>;
  }

  if (isLoaded && isAdminPage && !isAdmin) {
    return <Redirect to={{ pathname: "/home" }}></Redirect>;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        
        return <Component {...props} currentUser={currentUser}></Component>;
      }}
    />
  );
};

export default PrivateRoute;
